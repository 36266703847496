<script>
import Layout from "@/layouts/main";
import appConfig from "@/app.config";
import Timer from "@/components/Timer";
import Popup from "@/components/Popup";
import { mapGetters } from "vuex";
import Web3 from "web3";
var CryptoJS = require("crypto-js");
import abis from "../abis/lucky.json";
import address from "../address/address.json";
import WalletConnectProvider from '@walletconnect/web3-provider' 

const Web3Modal = window.Web3Modal.default
const Fortmatic = window.Fortmatic
// Web3modal instance
var web3Modal
var networkId
// Chosen wallet provider given by the dialog window
let provider
// Address of the selected account
//let selectedAccount;
provider = new WalletConnectProvider({
  rpc: {
    1: 'https://mainnet.infura.io/v3/0ad3bc79368a458fa8ad8415ced969d6',
  },
  qrcodeModalOptions: {
    mobileLinks: ['metamask', 'trust'],
  },
})
var accounts
provider.on('connect', async () => {
  accounts = await web3.eth.getAccounts()
  alert(accounts)
})
var web3 = new Web3(provider)
var MerkleTree = require("merkletreejs").MerkleTree;
var SHA256 = CryptoJS.SHA256;

const leaves = address.addresses.map((x) =>
  x.replace("0x", "0x000000000000000000000000")
);
const tree = new MerkleTree(leaves, SHA256, { sortPairs: true });
const root = tree.getRoot().toString("hex");

export default {
  page: {
    title: "Sale on going",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, Timer, Popup },
  watch: {},

  data() {
    return {
      ethAmount: 1,
      address: "",
      accountID: "",
      accountBalance: 0,
      abi: [],
      contract: [],
      wlClaimed: 0,
      account:"",
      // Contract
      isActive: false,
      isPresaleActive: false,
      isPrivateActive:false,
      currentSupply: 0,
      totalTokens: 9777,
      maxSupply: 9777,
      nftPrice: 190000000000000000,
      notAllowed: false,
      // Form data
      minted: false,
      isMinting: false,
    };
  },
  async created() {
    this.init()
    await this.loadWeb3();
    this.GetRoot();
    this.loadContractData();
  },
  mounted() {
  this.loadContractData()
  
  
  },
  methods: {
    /***********************************/
    /**
     * Kick in the UI action after Web3modal dialog has chosen a provider
     */
    fetchAccountData: async function () {
      // Get a Web3 instance for the wallet
      web3 = new Web3(provider)
      console.log('Web3 instance is', web3)
      // Load chain information over an HTTP API
      //document.querySelector("#network-name").textContent = chainData.name;
      // Get list of accounts of the connected wallet
      const accounts = await web3.eth.getAccounts()
      // MetaMask does not give you all accounts, only the selected account
      console.log('Got accounts', accounts)
      this.accounts = accounts[0]
      var accountID = this.accounts
      // Get Current Blockchain
      await web3.eth.net.getId().then(function (id) {
        networkId = id
      })
      if (networkId == 1) {
       
        this.isWalletConnected = true
        await web3.eth.getAccounts().then(async addr => {
          this.accounts = addr // Set User account into state
        })
        web3.eth.defaultAccount = accountID
       this.accountID = accountID;
        this.account = accountID;
        this.abi = abis.abi
        if(this.working){
          return
        }
       
         this.working=false
        this.connectContract()
      } else {
        alert('Please change your network to ETH mainnet!')
      }
    },
    init: async function () {
      console.log('Initializing example')
      console.log('WalletConnectProvider is', WalletConnectProvider)
      console.log('Fortmatic is', Fortmatic)
      console.log('window.web3 is', window.web3)
      // Check that the web page is run in a secure context,
      // as otherwise MetaMask won't be available
      if (location.protocol !== 'https:') {
        // https://ethereum.stackexchange.com/a/62217/620
        //const alert = document.querySelector("#alert-error-https");
        //alert.style.display = "block";
        //document.querySelector("#btn-connect").setAttribute("disabled", "disabled")
        return
      }
      // Tell Web3modal what providers we have available.
      // Built-in web browser provider (only one can exist as a time)
      // like MetaMask, Brave or Opera is added automatically by Web3modal
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              1: 'https://mainnet.infura.io/v3/0ad3bc79368a458fa8ad8415ced969d6',
            },
            network: 'mainnet',
            chainId: 0x04, //0x01
          },
        },
      }
      web3Modal = new Web3Modal({
        rpc: {
          1: 'https://mainnet.infura.io/v3/0ad3bc79368a458fa8ad8415ced969d6',
        },
        cacheProvider: false, // optional
        providerOptions, // required
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
      })
      console.log('Web3Modal instance is', web3Modal)
    },
connectWallet: async function () {
      console.log('Opening a dialog', web3Modal)
      try {
        provider = await web3Modal.connect()
      } catch (e) {
        console.log('Could not get a wallet connection', e)
        return
      }
      // Subscribe to accounts change
      provider.on('accountsChanged', () => {
        this.fetchAccountData()
      })
      // Subscribe to chainId change
      provider.on('chainChanged', () => {
        this.fetchAccountData()
      })
      // Subscribe to networkId change
      provider.on('networkChanged', () => {
        this.fetchAccountData()
      })
      //await this.refreshAccountData();
      this.fetchAccountData()
      this.isWalletConnected = true
      // const accounts = await web3.eth.getAccounts();
    },
    GetMerkleProof(walletAddress) {
      const leaf = walletAddress;
      return tree.getHexProof(leaf.replace("0x", "0x000000000000000000000000"));
    },
    GetRoot() {
      console.log("root");
      console.log(root);
      console.log(address.addresses.length);
      console.log("contract : " + this.address);
      return `0x${root}`;
    },
    async loadContractData() {
      const web3 = window.web3;
      const networkId = await web3.eth.net.getId();

      if (networkId !== abis.network) {
        window.alert("Please change to ethereum mainnet.");
        return;
      }

      this.abi = abis.abi;
      this.address = abis.address;
      this.contract = new web3.eth.Contract(this.abi, this.address);
      this.nftPrice = await this.contract.methods.NFTPrice().call();
      this.totalTokens = parseInt(await this.contract.methods.MAX_NFT_PUBLIC().call()) + 100;
      this.isActive = await this.contract.methods.isActive().call();
      this.currentSupply = parseInt(await this.contract.methods.totalSupply().call())+100;
      this.isPresaleActive = await this.contract.methods.isPresaleActive().call();
      this.isPrivateActive = await this.contract.methods.isPrivateActive().call();
     
      //    if(!this.isPrivateActive&&this.isPresaleActive){
      //   this.$router.push('whitelist')
      // }
      //  if(!this.isPrivateActive&&!this.isPresaleActive&&this.isActive){
      //   this.$router.push('public')
      // }
    },async loadWeb3() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);

        window.ethereum.on("accountsChanged", async (accounts) => {
          this.$store.dispatch("connect");
        });
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      } else { 
        window.alert("Non-Ethereum browser detected. You should consider trying MetaMask !");
      }

      await this.loadContractData();
      setInterval(
        function () {
          this.loadContractData();
        }.bind(this),
        1000
      );
    },
    //Minting Functionality
    async mint(e) {
      e.preventDefault();

      if (this.account === "") {
        window.alert("Please connect wallet first!");
        this.isMinting = false;
        return;
      }
      console.log(this.account);
      this.accountBalance = await window.web3.eth.getBalance(this.account);
      if (this.accountBalance <= this.nftPrice * this.ethAmount) {
        this.isMinting = false;
        alert(`Insufficient funds`);
        return;
      }
      this.isMinting = true;
      this.isActive = await this.contract.methods.isActive().call();
      this.isPresaleActive = await this.contract.methods
        .isPresaleActive()
        .call();
      console.log("isActive : ", this.isActive);
      console.log("isPresaleActive : ", this.isPresaleActive);

      if (!this.isActive) {
        this.isMinting = false;
        alert("Sale is not active yet!");
        return;
      }

      const noOfTokens = this.ethAmount;
      console.log("nftPrice : ", this.nftPrice);
      
        if (noOfTokens < 1 || noOfTokens == undefined) {
          alert("Select at least 1 NFT!");
        } else if (this.totalSupply >= this.currentSupply) {
          alert("Sold out!");
        } else {
          const result = await this.contract.methods
            .mintNFT(noOfTokens)
            .send({
              from: this.account,
              value: parseInt(this.nftPrice) * noOfTokens,
            })
            .on("receipt", function (res) {
              this.minted = true;
              this.isMinting = false;
              console.log("Receipt :", res);
            })
            .on("error", function (err) {
              this.isMinting = false;
              console.log(err);
              alert("Transaction Error");
            });
          this.minted = true;
          console.log("Test :", result);
        }
      
      this.isMinting = false;
    },
  },
  computed: {
    ...mapGetters([
      "nbTroopers",
      "isOpen",
      "HZNbalance",
      "HZNEearned",
      "HZNAPR",
      "stakedLP",
      "account",
      "ethBalance",
      "stakedEthLP",
      "ETHEearned",
      "ETHAPR",
      "rewardBalance",
    ]),
  },
};
</script>

<template>
  <Layout>
    <Popup />
    <section class="private text-white text-3xl relative h-screen w-screen">
      <div class="header py-6 w-full bg-default w-full grid grid-row">
        <div class="scroll-band font-saira lg:hidden">
          <div class="inner">
            <span v-for="i in 1000" :key="i">* WHITELIST GOLD ONLY  * WHITELIST GOLD ONLY</span>
          </div>
        </div>
        <div class="inner relative h-full w-full grid gap-4 px-8">
          <div
            class="
              title
              mb
              text-2xl
              lg:text-3xl
              font-semibold font-saira
              lg:absolute lg:l-0 lg:t-0
              w-full
              text-center
              uppercase
            "
          >
            Private sale
          </div>
          <div class="flex items-center justify-center gap-4 lg:hidden">
            <span style="font-size: 20px" class="text-xs lg:text-2xl uppercase">
              MINT ENDS IN
            </span>
            <Timer :isCountdown="true"
              :color="'#E91EA4'" />
          </div>

          <div class="flex items-center justify-between timers">
            <Timer :timezone="'PST'" :city="'Los Angeles'" />
            <Timer :timezone="'JST'" :city="'Tokyo'" class="block lg:hidden" />
            <Timer
              :timezone="'GMT'"
              :city="'London'"
              class="block lg:hidden"
            />

            <div class="hidden items-center justify-center gap-4 lg:flex">
              <span
                style="font-size: 20px"
                class="text-xs lg:text-2xl uppercase"
              >
                MINT ENDS IN
              </span>
              <Timer :isCountdown="true"
              :color="'#E91EA4'" />
            </div>
          </div>
        </div>
      </div>

      <div class="img-section">
        <div class="scroll-band lg font-saira">
          <div class="inner">
            <span v-for="i in 1000" :key="i">* WHITELIST GOLD ONLY! * WHITELIST GOLD ONLY!
            </span>
          </div>
        </div>
        <!-- <img
          src="../../assets/videos/PunkAngels_reveal_GIF_300px.gif"
          alt=""
          class="lg:hidden"
        />
        <img
          src="../../assets/videos/PunkAngels_reveal_GIF_600px.gif"
          alt=""
          class="hidden lg:block"
        /> -->
        <video autoplay loop muted class="lg:hidden" playsinline>
          <source
            src="../../assets/videos/PunkAngels_reveal_square.mp4"
            type="video/mp4"
          />
        </video>
        <video autoplay loop muted class="hidden lg:block" playsinline>
          <source
            src="../../assets/videos/PunkAngels_reveal_16_9.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div
        class="footer px-2 lg:px-9 py-6 w-full flex justify-between bg-default"
      >
        <div class="inner w-full h-full relative font-saira">
          <div class="form w-full flex items-center justify-center">
            <div class="text-center">
              <h1 v-if="accountID!=''" class="title pb-4 lg:pb-3 leading-10 font-saira">
                Mint your <br class="lg:hidden" />
                PUNK ANGELS NFT
              </h1>
              <h1 v-if="accountID==''" class="title pb-4 lg:pb-3 leading-10 font-saira">
                Connect your <br class="lg:hidden" />
                WALLET
              </h1>
              <p v-if="accountID!=''"  class="pb-3 subtitle">
                Price {{  parseFloat((nftPrice / 1000000000000000000)*ethAmount).toFixed(2)  }} ETH - Select The Quantity
                Below
              </p>
              <div v-if="accountID!=''"  class="flex flex-wrap pb-3 justify-center gap-2 lg:gap-11">
                <div
                  @click="ethAmount = nbr"
                  :class="{ selected: ethAmount === nbr }"
                  class="
                    h-12
                    w-12
                    rounded-full
                    flex
                    items-center
                    justify-center
                    text-4xl
                    digit
                    lg:cursor-pointer
                  "
                  v-for="(nbr, index) in [1, 2, 3, 5, 10, 25]"
                  :key="index"
                >
                  {{ nbr }}
                </div>
              </div>
              <!-- <p class="pb-8 text-base">
                Receive an ADDITIONAL 1 per 10 PUNK ANGELS minted in your
                WALLET!
              </p> -->
              </br>
              <button v-if="accountID!=''" @click="mint" class="rounded-full px-20 py-4 btn-default">
                MINT
              </button>
              <button v-if="accountID==''"
                @click="connectWallet"
                class="rounded-full btn-default font-saria btn-connect"
              >
                Connect
              </button>
            </div>
          </div>

          <div class="timers justify-between hidden lg:flex">
            <Timer :timezone="'JST'" :city="'Tokyo'" />
            <Timer :timezone="'GMT'" :city="'London'" />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>

<style lang="scss" scoped>
section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .header {
    position: absolute;

    top: 0;
    left: 0;
    @media (max-height: 953px) and (max-width: 500px) {
      min-height: 150px;
      padding: 10px 0;
    }
    .title {
      font-family: Saira Condensed;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      // line-height: 70px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.37);
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    @media (max-height: 953px) and (max-width: 500px) {
      min-height: 90px;
      padding-top: 0px;
    }
    .form {
      @media (min-width: 1024px) {
        position: absolute;
        bottom: -10%;
        left: 0;
      }
      .digit {
        background: #2d1630;
        border: 1px solid #e91ea4;
        box-shadow: 0px 0px 30px 4px rgba(233, 30, 164, 0.54);
        border-radius: 110px;
        &.selected {
          font-weight: bold;
          border: 4px solid #e91ea4;
          box-shadow: 0px 0px 30px 9px rgba(233, 30, 164, 0.54);
        }
        @media (max-height: 953px) and (max-width: 500px) {
          font-size: 30px;
        }
      }
      .title {
        font-style: normal;
        font-weight: 800;
        font-size: 60px;
        line-height: 70px;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.37);
        @media (max-height: 953px) and (max-width: 500px) {
          font-size: 40px;
          line-height: 42px;
        }
      }
      p,
      .subtitle {
        @media (max-height: 953px) and (max-width: 500px) {
          padding-bottom: 20px;
        }
      }
      .subtitle {
        @media (min-width: 1024px) {
          font-size: 32px;
          line-height: 40px;
        }

        font-family: "Saira Condensed";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.37);
      }

      button {
        width: 90%;
        background: #2d1630;
        border: 3px solid #e91ea4;
        box-sizing: border-box;
        box-shadow: 0px 0px 30px 9px rgba(233, 30, 164, 0.54);
        border-radius: 110px;
        @media (min-width: 1024px) {
          width: 60%;
          box-shadow: 0px 0px 30px 13px rgba(233, 30, 164, 0.54);
        }
        @media (max-height: 953px) and (max-width: 500px) {
          margin: 0;
          padding: 10px;
        }
      }
    }
  }

  .img-section {
    position: absolute;
    top: 176px;
    left: 0;
    z-index: -1;
    height: calc(100vh - 176px - 442px);
    width: 100%;

    img,
    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    @media (min-width: 1024px) {
      position: absolute;
      top: 80px;
      height: calc(100vh - 80px - 80px);
    }
    @media (max-height: 953px) and (max-width: 500px) {
      top: 150px;
      height: calc(100vh - 150px - 90px);
    }
  }

  .scroll-band {
    height: 34px;
    color: #e91ea4;
    text-transform: uppercase;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100vw;
    max-width: 100%;
    overflow-x: hidden;
    .inner {
      position: absolute;
      white-space: nowrap;
      will-change: transform;
      animation: marquee 1000s linear infinite;
    }
    @keyframes marquee {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-50%);
      }
    }
  }
}

.bg-default {
  background: #2d1630;
}
</style>
